import {getBodyContentSerializer, WebComponents} from '@hy-vee/react-web-and-mobile-ui-components';
import {useRouter} from 'next/router';

import {clipCoupon, login, seeCouponDetails} from '../utils/callback-helpers';
import {getLoginUrlWithRedirect, getLogoutUrl} from '../utils/redirect-to-login';
import {getComponentWithContextProps} from '../utils/serializer-helpers';
import {useModalContext} from '../components/modals/modal-provider';
import {onChangeAmountInCart} from '../utils/product-card-helpers';
import {WEB_SNAP_BADGE_DISPLAY} from '../enums/feature-toggle-names';
import {useFeatureToggle} from '../feature-toggle';

export const useSanitySerializer = (
    customCallbacks = {},
    customer = {},
    types = {},
) => {
    const router = useRouter();
    const {addToListModal, couponModal, storeSelector} = useModalContext();
    const {featureEnabled} = useFeatureToggle();
    const showSnapBadge = featureEnabled(WEB_SNAP_BADGE_DISPLAY);

    const contextProps = {
        callbacks: {
            addToListCallback: (type, productId) => addToListModal.addToList(type, productId),
            clipCallback: clipCoupon,
            getClientUrls: () => ({
                loginUrl: getLoginUrlWithRedirect(router.asPath),
                logoutUrl: getLogoutUrl(),
            }),
            loginCallback: () => {
                login(router.asPath);
            },
            onChangeAmountInCart,
            seeDetailsCallback: seeCouponDetails,
            showCouponModal: (id) => couponModal.show(id),
            storeSelectorVisibleCallback: () => {
                storeSelector.show();
            },
            ...customCallbacks,
        },
        userFlags: {
            customerId: customer?.customerId,
            hasFuelSaver: Boolean(customer?.fuelSaverCardUuid),
            hasStore: Boolean(customer?.store?.storeId),
            isAuthenticated: Boolean(customer?.customerUuid),
            showSnapBadge,
        },
    };

    const baseSerializer = getBodyContentSerializer(WebComponents, contextProps);
    const typesWithContextProps = Object.fromEntries(Object.entries(types).map(([type, component]) =>
        [type, getComponentWithContextProps(component, contextProps)]));

    return {
        ...baseSerializer,
        types: {
            ...baseSerializer.types,
            ...typesWithContextProps,
        },
    };
};
