import gql from 'graphql-tag';

import {
    cmsCardCollectionFragment,
    cmsCardSwimlaneFragment,
    cmsDecorationFragment,
    cmsHeroBannerFragment,
    cmsImageCollectionFragment,
    cmsMarketingBannerFragment,
    cmsSegmentDisplay,
    cmsTextBlockFragment,
    cmsBottomCallToAction,
    cmsFuelSaverLandingRedemptionLocations,
    cmsSocialMediaDisplay,
} from '../fragments/sanity-fragments';

export const getPerksWebLandingPageContent = gql`
    ${cmsHeroBannerFragment}  
    ${cmsTextBlockFragment}
    ${cmsDecorationFragment}
    ${cmsCardCollectionFragment}
    ${cmsImageCollectionFragment}
    ${cmsMarketingBannerFragment}
    ${cmsCardSwimlaneFragment}
    ${cmsSegmentDisplay}
    ${cmsBottomCallToAction}
    ${cmsFuelSaverLandingRedemptionLocations}
    ${cmsSocialMediaDisplay}

    query allCmsFuelSaverLandingPage (
        $locationIds: [ID!]!
        $storeId: ID
    ) {
        allCmsFuelSaverLandingPage {
            body {
                ...IHeroBanner
                ...ITextBlock
                ...IDecoration
                ...ICardCollection
                ...IImageCollection
                ...IBanner
                ...ICardSwimlane
                ...ISegmentDisplay
                ...ISBottomCallToAction
                ...IFuelSaverLandingRedemptionLocations
                ...ISocialMediaDisplay
            }
        }
    }
`;

export const getPartnerLocations = gql`
    query allCmsLoyaltyWebPerksParticipatingFuelLocations($partnerId: ID) {
        allCmsLoyaltyWebPerksParticipatingFuelLocations (where: {partner: {_id: {eq: $partnerId}}}) {
            title
            subtitle
            fuelLocations {
                _id
                _key
                address
                cityStateZip
            }
        }
    }
`;
