import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {useRouter} from 'next/router';
import {BodyContent} from '@hy-vee/react-web-and-mobile-ui-components';
import {IActionButtonAction} from '@hy-vee/react-web-and-mobile-ui-components/cjs/interfaces/action-button-interfaces';

import {getPerksWebLandingPageContent} from '../../graphql/queries/sanity-queries';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from '../../enums/auth-types';
import {useSanitySerializer} from '../../hooks/use-sanity-serializer';
import LoadingIndicator from '../LoadingIndicator';
import NotFoundPage from '../../pages/404';

import BottomCallToAction from './bottom-call-to-action/bottom-call-to-action';
import RedemptionLocations from './redemption-locations/redemption-locations';
import RedemptionLocationsModal from './redemption-locations/redemption-locations-modal';
import LandingHero from './hero/landing-hero';

export const areEqual = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);

export const FuelSaverContent = React.memo(({customer, locationIds, storeId}) => {
    const router = useRouter();
    const [selectedFuelSaverPartner, setSelectedFuelSaverPartner] = useState(null);
    const {
        data: pageData,
        loading: pageDataLoading,
    } = useQuery(getPerksWebLandingPageContent, {
        errorPolicy: 'all',
        variables: {
            authType: customer?.customerUuid ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            locationIds,
            storeId,
        },
    });
    const partnerLocations = pageData?.allCmsFuelSaverLandingPage[0].body.find((component) => component._type === 'cmsFuelSaverLandingRedemptionLocations');

    useEffect(() => {
        const partnerName = router.query?.['fuel-saver-partner'];
        const matchingPartner = partnerLocations ? partnerLocations.externalPartners.find((partner) => partner.partnerName === partnerName) : null;

        if (matchingPartner) {
            setSelectedFuelSaverPartner(matchingPartner);
        }
    }, [router.query, partnerLocations]);

    const actionButtonClickCallback = (button, actionContext) => {
        if (button.buttonAction === IActionButtonAction.FS_SIGN_UP) {
            router.push(`/my-account/sign-up?redirect=${router.asPath}`);
        } else if (button.buttonAction === IActionButtonAction.FS_ENTER_CARD_NUMBER) {
            router.push(`/my-account/sign-up?redirect=${router.asPath}`);
        } else if (button.buttonAction === IActionButtonAction.FS_DISPLAY_PARTNER_LOCATIONS) {
            setSelectedFuelSaverPartner(actionContext);
        }
    };

    const serializer = useSanitySerializer(
        {
            actionButtonClickCallback,
        },
        customer,
        {
            cmsFuelSaverLandingRedemptionLocations: RedemptionLocations,
            cmsLoyaltyWebPerksWebBottomCallToAction: BottomCallToAction,
            cmsLoyaltyWebPerksWebHeroBanner: LandingHero,
        },
    );

    if (pageDataLoading) {
        return <LoadingIndicator />;
    }

    if (!pageData) {
        return <NotFoundPage />;
    }

    return (
        <div>
            <BodyContent
                body={pageData.allCmsFuelSaverLandingPage[0].body}
                serializers={serializer}
            />
            {
                selectedFuelSaverPartner &&
                    <RedemptionLocationsModal
                        onClose={() => setSelectedFuelSaverPartner(null)}
                        partner={selectedFuelSaverPartner}
                    />
            }
        </div>
    );
}, areEqual);
